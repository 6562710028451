import React from "react";

export default function CardBox(props) {
  let { cardTopText, cardMiddleText, cardInputPlaceholder, cardButtonText } =
    props;
  return (
    <div className="card-wrapper">
      <div className="card-top-text">
        <h2>{cardTopText}</h2>
      </div>
      <div className="card-middle-text">
        <p>{cardMiddleText}</p>
      </div>
      <div className="card-input-wrapper">
        <input type="text" placeholder={cardInputPlaceholder}></input>
        <button>{cardButtonText}</button>
      </div>
    </div>
  );
}
