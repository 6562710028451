import React from "react";
import "./../assets/css/Home.css";
import secSection1 from "../assets/images/sec-section-1.png";
import secSection2 from "../assets/images/sec-section-2.png";
import secSection3 from "../assets/images/sec-section-3.png";
import secSection4 from "../assets/images/sec-section-4.png";
import secSection5 from "../assets/images/sec-section-5.png";
import thirdSection1 from "../assets/images/third-section-1.png";
import thirdSection2 from "../assets/images/third-section-2.png";
import thirdSection3 from "../assets/images/third-section-3.png";
import CardBox from "../components/CardBox";
import InfoCard from "../components/InfoCard";
import Testimonial from "../components/Testimonial";
import Spline from '@splinetool/react-spline';
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const handleMoreAboutUsClick = () => {
    
    navigate("/about"); 
  };
  return (
    <>
      <section className="home-top-section">
  <div className="spline-background">
    <Spline scene="https://prod.spline.design/GXdshSKNL0rnPMqT/scene.splinecode" />
  </div>

    <CardBox
      cardTopText="Strategic M&A Advisory for Sustainable Growth"
      cardMiddleText="Empowering your growth through tailored mergers and acquisitions strategies"
      cardInputPlaceholder="Enter your email"
      cardButtonText="Connect"
    />

</section>

      <section className="section home-second-section">
        <div className="section-top-header-text">
          <h2 className="section-primary-text primary-text text-center">
            ADUNOVA PERSPECTIVA
          </h2>
          <h4 className="section-secondary-text secondary-text text-center">
            redefining M&A advisory for tomorrow's market leaders
          </h4>
          <p className="section-para-text para-text text-center">
            utilizing extensive industry expertise and global insights to
            optimize M&A results in a dynamic economy. Our innovative approach
            enhances how companies utilize mergers & acquisitions to generate
            lasting growth and stakeholder value.
          </p>
        </div>
        
        <div className="row w-100 mx-0 mt-5">
          <div className="col-sm-12 col-md-6 col-lg-5 p-0">
            <img className="w-100" src={secSection1} alt={secSection1}></img>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-7 d-flex align-items-center px-sm-2 px-lg-5 margin-top-resp">
            <div className="row">
              <div className="col-sm-12 col-md-6 py-2">
                <img className="w-25" src={secSection2} alt={secSection2}></img>
                <h2 className="primary-text mt-2">M&A Vision</h2>
                <h4 className="para-text">
                  analyze market trends and company data to create targeted M&A
                  strategies that align with your business goals
                </h4>
              </div>
              <div className="col-sm-12 col-md-6 py-2">
                <img className="w-25" src={secSection3} alt={secSection3}></img>
                <h2 className="primary-text mt-2">M&A deal structuring</h2>
                <h4 className="para-text">
                  our team guides you through deal structuring, ensuring optimal
                  terms that align with your specific objectives and industry
                  dynamics
                </h4>
              </div>
              <div className="col-sm-12 col-md-6 py-2">
                <img className="w-25" src={secSection4} alt={secSection4}></img>
                <h2 className="primary-text mt-2">M&A reach</h2>
                <h4 className="para-text">
                  access our international network to identify opportunities and
                  navigate cross-border transactions effectively.
                </h4>
              </div>
              <div className="col-sm-12 col-md-6 py-2">
                <img className="w-25" src={secSection5} alt={secSection5}></img>
                <h2 className="primary-text mt-2">M&A due dillgence</h2>
                <h4 className="para-text">
                  identify and optimize synergies to maximize deal value and
                  ensure successful post-merger integration
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row w-100 mx-0 mt-5">
          <div className="col-sm-12 col-md-6 col-lg-7">
            <h2 className="section-primary-text primary-text">
              ADUNOVA PROPOSITUM
            </h2>
            <h4 className="section-secondary-text secondary-text pe-5">
              innovative approaches to mergers and acquisitions for traditional
              and emerging markets
            </h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-5 d-flex flex-column justify-content-end mt-sm-0 ">
            <p className="para-text mt-sm-0 ">
              in a rapidly evolving market, Adunova brings innovative M&A
              strategies to the forefront. We combine deep old school industry
              knowledge with a modern forward-thinking approach to guide
              businesses through transformative mergers and acquisitions.
            </p>
            <button className="primary-button" onClick={handleMoreAboutUsClick}>
              more about us
            </button>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-sm-12 col-lg-6 d-flex flex-column gap-5">
            <div
              className="w-100"
              style={{
                maxWidth: "350px",
              }}
            >
              <InfoCard
                infoImage={thirdSection1}
                cardTopText="86%"
                cardParaText="of CEOs believe India's economic growth will improve over the next 12 months, indicating a positive M&A environment"
                cardButtonText="source: PwC"
              />
            </div>
            <div className="w-100">
              <InfoCard
                infoImage={thirdSection3}
                cardTopText="86%"
                cardParaText="of CEOs believe India's economic growth will improve over the next 12 months, indicating a positive M&A environment"
                cardButtonText="source: PwC"
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 d-flex justify-content-end ">
            <div
              className="w-100 mt-5"
              style={{
                maxWidth: "400px",
              }}
            >
              <InfoCard
                infoImage={thirdSection2}
                cardTopText="86%"
                cardParaText="of CEOs believe India's economic growth will improve over the next 12 months, indicating a positive M&A environment"
                cardButtonText="source: PwC"
                margin="trueLeft"
              />
            </div>
          </div>
        </div>

        <Testimonial/>
      </section>
    </>
  );
};

export default Home;
