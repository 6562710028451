import React, { useState } from "react";
import "../assets/css/Header.css";
import Menu from "../assets/images/menu.png";
import Close from "../assets/images/close.png";
import { Link } from "react-router-dom";


export default function Headers() {
  // Use React state to manage the open/close state of the header
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the open state when the button is clicked
  const openHeaderHandle = () => {
    setIsOpen(!isOpen);
  };

  return (
    // Conditionally apply classes based on the state
    <header className={isOpen ? "header-show" : ""}>
      <div className={isOpen ? "top-row d-none" : "top-row"}>
        <div className="logo-box">
          <Link to={'/home'}>
            Adunova <span>Ventures</span>
          </Link>
        </div>
        {/* Ensure the button only toggles the state when clicked */}
        <button onClick={openHeaderHandle} aria-label="Toggle Menu">
          <img src={Menu} alt="Menu Icon" />
        </button>
      </div>
      <button
        className={isOpen ? "close-btn" : "d-none"}
        onClick={openHeaderHandle}
        aria-label="Toggle Menu"
      >
        <img src={Close} alt="Close Icon" />
      </button>
      <div className={isOpen ? "row h-100 mx-0" : "row h-100 mx-0 d-none"}>
        <div className="col-sm-12 col-md-7 header-show-left">
          <div className="logo-box">
            <Link to={'/home'}>
              Adunova <span>Ventures</span>
            </Link>
          </div>
          <div className="tabs">
            <div className="tab">
              <Link onClick={openHeaderHandle} className="" to="/about">
                about
              </Link>
              <span>people, purpose, & persepective</span>
            </div>
            <div className="tab mt-4">
              <Link onClick={openHeaderHandle} className="" to="/contact">
                contact
              </Link>
              <span>powered by passion - get in touch</span>
            </div>
          </div>
          <div className="deatils">
            <a className="text-para" href="/">
              mergers@adunova.com
            </a>
            <p>
              #206, Suncity Success Tower Golf Course Extension Rd Gurgaon
              122002
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-5 header-show-right">
          <div className="mail-box">
            <div className="card-wrapper">
              <div className="card-top-text">
                <h2>tomorrow's strategies for value-driven M&A</h2>
              </div>
              <div className="card-middle-text">
                <p>join us as we shape the future of strategic mergers and acquisitions.</p>
              </div>
              <div className="card-input-wrapper">
                <input type="text" placeholder="enter your email"></input>
                <button>connect</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
