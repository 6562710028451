import React from "react";

export default function InfoCard(props) {
  let {
    infoImage,
    cardTopText,
    cardParaText,
    cardButtonText,
  } = props;

  return (
    <div className="info-card-wrapper">
      <div className="info-card-image">
        <img className="w-100" src={infoImage} alt="Info Card" />
      </div>
      <div className="info-card-top-text">
        <h2>{cardTopText}</h2>
      </div>
      <div className="info-card-para-text">
        <p>
          {cardParaText}
          <button className="ms-2 info-card-button">{cardButtonText}</button>
        </p>
      </div>
    </div>
  );
}
