import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import rightArrow from "../assets/images/right-arrow.png";
import leftArrow from "../assets/images/left-arrow.png";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Testimonial() {
  const { pathname } = useLocation();
  const main = useRef();
  const main2 = useRef();

  useEffect(() => {
    handleScrollAnimation();
  }, [pathname]); // Dependency on pathname to run effect on route change

  const handleScrollAnimation = () => {
    const ctx = gsap.context(() => {
      // Ensure ScrollTrigger is re-registered on each pathname change
      gsap.registerPlugin(ScrollTrigger);

      // Select the testimonial texts using gsap.utils.toArray
      const boxes = gsap.utils.toArray(".testimonial-text");

      // Check if boxes are not empty
      if (boxes.length >= 2) {
        // First text animation
        gsap.to(boxes[0], {
          x: 0, // Move to position 0 from the left
          right: "10px",
          opacity: 1,
          scrollTrigger: {
            trigger: boxes[0],
            start: "bottom bottom",
            end: "center center",
            scrub: true,
            markers: false,
          },
        });

        // Second text animation
        gsap.to(boxes[1], {
          x: 0, // Move to position 0 from the right
          left: "10px",
          opacity: 1,
          scrollTrigger: {
            trigger: boxes[1],
            start: "bottom bottom",
            end: "center center",
            scrub: true,
            markers: false,
          },
        });
      }
    }, main); // Scope animations to this component using the main ref

    // Cleanup function to kill ScrollTrigger instances on unmount
    return () => {
      ctx.revert(); // Revert all animations inside this context
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill()); // Kill all ScrollTriggers
    };
  };

  return (
    <div className="row mt-5" ref={main}>
      <div className="col-12 py-4 d-flex justify-content-end">
        <button className="testimonial-btn">
          <img className="testimonial-img" src={rightArrow} alt="Right Arrow" />
          <img className="testimonial-img" src={rightArrow} alt="Right Arrow" />
        </button>
      </div>
      <div className="col-12">
        <div className="row position-relative testimonial-box">
          <div className="col-sm-12 col-lg-12">
            <p className="testimonial-text" id="testimonial-text1">
              Shaping the future
            </p>
          </div>
          <div
            className="col-sm-12 col-lg-12 d-flex justify-content-end"
            ref={main2}
          >
            <p className="testimonial-text" id="testimonial-text2">
              of strategic growth
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 py-4">
        <button className="testimonial-btn">
          <img className="testimonial-img" src={leftArrow} alt="Left Arrow" />
          <img className="testimonial-img" src={leftArrow} alt="Left Arrow" />
        </button>
      </div>
    </div>
  );
}
