import React from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import aboutSecSection from "../assets/images/about-sec-section.png";
import Home from "../assets/images/home.png";
import Globel from "../assets/images/globel.png";
import Trade from "../assets/images/trade.png";
import Star from "../assets/images/star.png";
import Champ from "../assets/images/champ.png";
import topSec1 from "../assets/images/top-sec-1.png";
import topSec2 from "../assets/images/top-sec-2.png";
import Testimonial from "../components/Testimonial";

gsap.registerPlugin(ScrollTrigger);

export default function AboutUs() {
  return (
    <section className="section home-second-section">
      <div className="row w-100 mx-0 mt-5">
        <div className="col-sm-12 col-md-6 col-lg-5">
          <h2 className="section-primary-text primary-text">
            ADUNOVA PROPOSITUM
          </h2>
          <h4 className="section-secondary-text secondary-text pe-5">
            strategic M&A, value-driven solutions
          </h4>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-7 d-flex flex-column mt-sm-0 ">
          <p className="para-text mt-sm-0 ">
            Adunova, born from the fusion of the Latin words "adunare" (to
            unite) and "nova" (innovation), is a mergers & acquisitions firm
            dedicated to uniting businesses & creating new opportunities through
            strategic deals
          </p>
        </div>
      </div>

      <div className="row w-100 mx-0 mt-5">
        <div className="col-sm-12 col-md-6 col-lg-5">
          <img
            className="w-75 mb-5 p-sm-2 p-md-3 p-lg-0"
            src={topSec1}
            alt="Top Section 1"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-7 d-flex flex-column  mt-sm-0 mt-lg-5">
          <img className="w-100" src={topSec2} alt="Top Section 2" />
        </div>
      </div>

      <Testimonial />

      <div>
        <div className="row w-100 mx-0 mt-5 about-info p-sm-2 p-md-3 p-lg-5">
          <div className="col-sm-12 col-md-6 col-lg-5 p-sm-2 p-lg-0">
            <img
              className="w-100 mt-2 mt-lg-0"
              src={aboutSecSection}
              alt="About Section"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-7 d-flex ps-sm-2 ps-lg-5">
            <div className="row">
              <div className="col-12 py-2">
                <h2 className="primary-text mt-2">
                  consistency and reliability
                </h2>
                <h4 className="secondary-text">
                  serving established firms to emerging startups
                </h4>
                <p className="para-text">
                  An accomplished team of M&A specialists, financial experts,
                  and industry veterans uniting to deliver bespoke merger and
                  acquisition solutions that are inventive and pertinent for
                  today's dynamic business terrain.
                </p>
                <p className="para-text">
                  Our focus is on strategic growth and delivering impactful M&A
                  advisory services.
                </p>
              </div>
              <div className="col-sm-12 col-lg-6">
                <p className="info-card-top-text mb-0">20+ Years</p>
                <p className="para-text">of Industry experience</p>
              </div>
              <div className="col-sm-12 col-lg-6">
                <p className="info-card-top-text mb-0">Global</p>
                <p className="para-text">operational reach</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row w-100 mx-0 mt-5">
        <div className="col-sm-12 col-md-10 col-lg-8">
          <h2 className="section-primary-text primary-text">VISION & VALUES</h2>
          <h4 className="section-secondary-text secondary-text">
            a strategic future of growth — where mergers and acquisitions
            amplify our clients' business potential
          </h4>
        </div>
      </div>

      <div className="row w-100 mx-0 mt-5 justify-content-between detail-warapper">
        <div className="detail-box w-auto d-flex flex-column align-items-center text-center">
          <div className="img-wrapper">
            <img src={Globel} alt="Global Reach" />
          </div>
          <p className="para-text mt-3">Global Reach</p>
        </div>
        <div className="detail-box w-auto d-flex flex-column align-items-center text-center">
          <div className="img-wrapper">
            <img src={Home} alt="Regulatory Expertise" />
          </div>
          <p className="para-text mt-3">Regulatory Expertise</p>
        </div>
        <div className="detail-box w-auto d-flex flex-column align-items-center text-center">
          <div className="img-wrapper">
            <img src={Trade} alt="Data-Driven Insights" />
          </div>
          <p className="para-text mt-3">Data-Driven Insights</p>
        </div>
        <div className="detail-box w-auto d-flex flex-column align-items-center text-center">
          <div className="img-wrapper">
            <img src={Star} alt="Proven Methodologies" />
          </div>
          <p className="para-text mt-3">Proven Methodologies</p>
        </div>
        <div className="detail-box w-auto d-flex flex-column align-items-center text-center">
          <div className="img-wrapper">
            <img src={Champ} alt="Industry-Leading Experience" />
          </div>
          <p className="para-text mt-3">Industry-Leading Experience</p>
        </div>
      </div>
    </section>
  );
}
