import React from "react";
import barRightArrow from "../assets/images/bar-right-arrow.png";
import Adjust from "../assets/images/adjust.png";
import Like from "../assets/images/like.png";
import ContactTop from "../assets/images/contact-top.png";
import Email from "../assets/images/email.png";
import OpenBook from "../assets/images/open-book.png";

export default function contact() {
  return (
    <section className="section">
      <div className="row w-100 mx-0 mt-5">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <h2 className="section-primary-text primary-text">Contact us</h2>
          <h4 className="section-secondary-text secondary-text pe-5">
            to explore opportunities for your business growth
          </h4>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-sm-12 col-md-4 contact-top-sec">
          <div>
            <img src={Email} alt={Email} />
            <p className="text-para">GENERAL INQUIRIES</p>
            <a href="/">MERGERS@ADUNOVA.COM</a>
          </div>
          <div>
            <img src={OpenBook} alt={OpenBook} />
            <p className="text-para">
              #206, Suncity Success Tower Golf Course Extension Rd Gurgaon
              122002
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
          <img className="w-100" src={ContactTop} alt={ContactTop} />
        </div>
      </div>

      <div className="section-top-header-text mt-5">
        <h2 className="section-primary-text primary-text text-center">
          Reach out & Get Started
        </h2>
      </div>

      <div className="row w-100 mx-0 mt-5 justify-content-between detail-warapper px-sm-2 px-md-5">
        <div className="detail-box w-auto d-flex flex-column align-items-center text-center">
          <div className="img-wrapper">
            <img src={barRightArrow} alt={barRightArrow}></img>
          </div>
          <p className="para-text mt-3">1. Initial Consultation</p>
        </div>
        <div className="detail-box w-auto d-flex flex-column align-items-center text-center">
          <div className="img-wrapper">
            <img src={Adjust} alt={Adjust}></img>
          </div>
          <p className="para-text mt-3">2. Customized Strategy Development</p>
        </div>
        <div className="detail-box w-auto d-flex flex-column align-items-center text-center">
          <div className="img-wrapper">
            <img src={Like} alt={Like}></img>
          </div>
          <p className="para-text mt-3">3. Implementation and Support</p>
        </div>
      </div>
    </section>
  );
}
