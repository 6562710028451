import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import "./App.css";
import Home from './Pages/home';
import AboutUs from './Pages/aboutUs';
import Contact from './Pages/contact';
import Footer from './components/Footer';
import Headers from './components/Headers';
import ScrollToTop from './components/ScrollToTop';

const App = () => (
  <Router>
    <ScrollToTop />
    <Headers/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path='/about' element={<AboutUs />} />
      <Route path='/contact' element={<Contact />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes> 
    <Footer />
  </Router>
);

export default App;
